<template>
<div class="tw-flex  tw-w-full tw-justify-center tw-items-center "
     style="background: white;">

  <v-col sm="12" lg="5" class="tw-hidden lg:tw-flex left-side" :style="{backgroundImage:`url(${bg})`}">
    <img
      :src="logo"
      alt="logo"
      class="logo"
      @click="goHome"
    />

    <h6 class="hey-mate tw-mt-10">Hey mate!</h6>

    <h6 class="lets-set tw-py-5">Let’s Get You Set</h6>

    <h6 class="lets-set-desc">We’re excited to have you! We just need some more information.</h6>
  </v-col>
  <v-col sm="12" lg="7" class="right-hand-side">
    <div class="right-hand-side-content">
    <img
      :src="logo"
      alt="logo"
      class="logo-mobile tw-flex lg:tw-hidden"
      @click="goHome"
    />
    <h5 class="right-hand-side-header">Profile Update</h5>
    <h6 class="right-hand-side-desc tw-py-4">Only your firstname would be visible to others</h6>

    <h6 class="right-hand-side-desc tw-py-4 tw-flex md:tw-hidden tw-self-center" >Parent | Guardian</h6>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(updateProfile)">
        <v-row class="mobile">
          <v-col sm="12" lg="6">
          <validationProvider
            name="First name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="tw-pt-4 tw-mb-4" :class="classes">
              <v-text-field
                placeholder="First name"
                solo
                hide-details
                type="text"
                v-model="onboardData.firstname"
                required
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </validationProvider>
          </v-col>
          <v-col sm="12" lg="6">
            <validationProvider
              name="Last name"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="Last name"
                  solo
                  hide-details
                  type="text"
                  v-model="onboardData.lastname"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
        </v-row>

        <v-row class="mobile">
          <v-col sm="12" lg="6">
            <validationProvider
              name="Email"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="Email"
                  solo
                  hide-details
                  type="email"
                  v-model="onboardData.email"
                  required
                  readonly
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
          <v-col sm="12" lg="6">
            <validationProvider
              name="ID Card"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  color="#F66B05"
                  placeholder="ID Card"
                  solo
                  hide-details
                  type="text"
                  v-model="logoImage"
                  required
                  readonly
                  append-icon="mdi-plus"
                >
                <template #append-outer>
                  <v-btn text class="upload" @click="onLogoFile()">Upload </v-btn>
                </template>
                </v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
            <input type="file" ref="logoImage" accept="image/*" style="display: none" @change="onLogoImagePicked">
          </v-col>
        </v-row>

        <v-row class="mobile">
          <v-col sm="12" lg="6">
            <validationProvider
              name="Phone Number"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="Phone Number"
                  solo
                  hide-details
                  type="tel"
                  v-model="onboardData.phoneNumber"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
          <v-col sm="12" lg="6">
            <validationProvider
              name="Country"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-combobox
                  color="#F66B05"
                  placeholder="Country"
                  solo
                  hide-details
                  type="text"
                  v-model="onboardData.address.country"
                  :items="countries"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  item-value="name"
                  required
                  @change="getCountiesOrState"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
        </v-row>



        <v-row class="mobile">
          <v-col sm="12" lg="6">
            <validationProvider
              name="County Or State"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-combobox
                  color="#F66B05"
                  placeholder="County Or State"
                  solo
                  hide-details
                  type="text"
                  append-icon="mdi-chevron-down"
                  item-text="name"
                  item-value="name"
                  :items="countiesOrStates"
                  v-model="onboardData.address.county"
                  required
                  @change="getCity"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
          <v-col sm="12" lg="6">
            <validationProvider
              name="Town/City"
              rules="required"
              v-slot="{ classes, errors }">
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-combobox
                  color="#F66B05"
                  placeholder="Town/City"
                  append-icon="mdi-chevron-down"
                  solo
                  hide-details
                  type="text"
                  v-model="onboardData.address.city"
                  required
                  :items="cities"
                ></v-combobox>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
        </v-row>

        <v-row class="mobile">
          <v-col sm="12" lg="3">
            <validationProvider
              name="Postal Code"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="Postal Code"
                  solo
                  hide-details
                  type="text"
                  v-model="onboardData.address.postalCode"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
          <v-col sm="12" lg="9">
            <validationProvider
              name="House Address"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div class="tw-pt-4 tw-mb-4" :class="classes">
                <v-text-field
                  placeholder="House Address"
                  solo
                  hide-details
                  type="text"
                  v-model="onboardData.address.houseNumber"
                  required
                ></v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </validationProvider>
          </v-col>
        </v-row>
        <base-button
          class="mt-8"
          width="100%"
          button-text="Complete"
          :loading="loading"
          type="submit"
        ></base-button>

      </form>
    </validation-observer>
    </div>
  </v-col>
  <modal :dialog="showModal" :icon="check" section="main" title="Great, You are all Set!"
         description="You are ready to start applying to the hoop camp." additional-text="Access Dashboard" @close="routeToDashboard"/>
</div>
</template>

<script>
import bg from "@/assets/bg_basketball.png"
import logo from "@/assets/logo.svg"
import BaseButton from "@/components/reuseables/BaseButton";
import {
  getAllCitiesInAState,
  getAllCountries,
  getAllStatesByCountry, updateGuardianById,
  uploadToCloudinary
} from "@/services/api/APIService";
import Modal from "@/components/reuseables/Modal";
import check from "@/assets/check.svg"
export default {
  name: "GuardianOnboarding",
  components: { Modal, BaseButton },
  data(){
    return{
     bg,
      logo,
      check,
      onboardData:{
       address:{}
      },
      loading: false,
      countries:[],
      countiesOrStates:[],
      cities:[],
      logoImage :"",
      image : null,
      showModal : false
    }
  },
  computed:{
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },
  },
  methods:{
    routeToDashboard(){
      this.$router.push({name : 'MainDashboard'})
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event){
      const files = event.target.files[0]
      try {

        const fileReader =  new FileReader()
        fileReader.addEventListener('load', async () => {
          this.recoveredImg = ""
          this.image = fileReader.result
          this.logoImage = files.name
        })
        fileReader.readAsDataURL(files)
      } catch (err) {
        console.log(err.response)
      }

    },
    goHome() {
      this.$router.push({name:"HomePage"})
    },
   async updateProfile(){
      this.loading = true

     let uploadData = { file: this.image, upload_preset: this.preset }
       const response = await uploadToCloudinary(uploadData)
       this.onboardData.idCard = response.data.url
     if (this.onboardData.address.country.name){
       this.onboardData.address.country = this.onboardData.address.country.name
     }
     if (this.onboardData.address.county.name){
       this.onboardData.address.county = this.onboardData.address.county.name
     }
     if (this.onboardData.address.city.name){
       this.onboardData.address.city = this.onboardData.address.city.name
     }
     updateGuardianById(this.onboardData).then(res =>{
       console.log(res.data)
       this.showModal = true
     }).catch((err) => {
       this.loading = false;
         console.log(err,"in compo err");
         this.$displaySnackbar({
           message: err.response.data.details[0],
           success: false,
         })})
    },
    async getCountries() {
      try {
        const response = await getAllCountries()
        this.countries = response.data.data
      } catch(err) {
        console.log(err.response)
      }
    },
    async getCountiesOrState() {
      try {
        let data = {
          country: this.onboardData.address.country
        }
        if (this.onboardData.address.country.name){
          data.country = this.onboardData.address.country.name
        }
        const response = await getAllStatesByCountry(data)
        this.countiesOrStates = response.data.data.states

      } catch(err) {
        console.log(err.response)
      }
    },
    async getCity() {
        try {
          let data = {
            country: this.onboardData.address.country,
            state: this.onboardData.address.county
          };
          if (this.onboardData.address.country.name){
            data.country = this.onboardData.address.country.name;
          }
          if (this.onboardData.address.county.name){
            data.state = this.onboardData.address.county.name
          }
          console.log(data)
          const response = await getAllCitiesInAState(data);
          console.log(response);
          this.cities = response.data.data;

        } catch (err) {
          console.log(err.response);
          this.cities = [];
        }

    },
  },
  mounted() {
    this.getCountries()
  },
  created() {
    let userData = JSON.parse(sessionStorage.getItem('userData'))
    this.onboardData.email = userData.email
  }
};
</script>

<style scoped lang="scss">

.right-hand-side{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 7rem;
  min-height: 100vh;

  @media screen and (max-width: 1024px) {
    padding: 1.5rem;

  }

}


.right-hand-side-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #F66B05;
  padding-top: 5rem;

  @media screen and(max-width: 1024px) {
    padding-top: 0;
  }
}
.right-hand-side-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  color: #828282;
}
.upload{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 35px;
  color: #F66B05;
  text-transform: none;
}

.left-side {
  width: 100%;
  min-height: 100vh;
  height: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 5rem 7rem !important;
  justify-content: flex-start;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.hey-mate{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  letter-spacing: 0;
  color: #FFFFFF;
}

.logo{
  height: auto;
  width: 241px;
  cursor: pointer;
  margin-left: -1rem;
}

.logo-mobile{
  height: auto;
  width: 157px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.lets-set{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0;
  color: #FFFFFF;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
}

.lets-set-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  width: 40%;
}

.mobile{
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}
</style>