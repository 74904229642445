var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex  tw-w-full tw-justify-center tw-items-center ",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden lg:tw-flex left-side",style:({backgroundImage:("url(" + _vm.bg + ")")}),attrs:{"sm":"12","lg":"5"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo,"alt":"logo"},on:{"click":_vm.goHome}}),_c('h6',{staticClass:"hey-mate tw-mt-10"},[_vm._v("Hey mate!")]),_c('h6',{staticClass:"lets-set tw-py-5"},[_vm._v("Let’s Get You Set")]),_c('h6',{staticClass:"lets-set-desc"},[_vm._v("We’re excited to have you! We just need some more information.")])]),_c('v-col',{staticClass:"right-hand-side",attrs:{"sm":"12","lg":"7"}},[_c('div',{staticClass:"right-hand-side-content"},[_c('img',{staticClass:"logo-mobile tw-flex lg:tw-hidden",attrs:{"src":_vm.logo,"alt":"logo"},on:{"click":_vm.goHome}}),_c('h5',{staticClass:"right-hand-side-header"},[_vm._v("Profile Update")]),_c('h6',{staticClass:"right-hand-side-desc tw-py-4"},[_vm._v("Only your firstname would be visible to others")]),_c('h6',{staticClass:"right-hand-side-desc tw-py-4 tw-flex md:tw-hidden tw-self-center"},[_vm._v("Parent | Guardian")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProfile)}}},[_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"First name","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.onboardData.firstname),callback:function ($$v) {_vm.$set(_vm.onboardData, "firstname", $$v)},expression:"onboardData.firstname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Last name","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.onboardData.lastname),callback:function ($$v) {_vm.$set(_vm.onboardData, "lastname", $$v)},expression:"onboardData.lastname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Email","solo":"","hide-details":"","type":"email","required":"","readonly":""},model:{value:(_vm.onboardData.email),callback:function ($$v) {_vm.$set(_vm.onboardData, "email", $$v)},expression:"onboardData.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"ID Card","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"color":"#F66B05","placeholder":"ID Card","solo":"","hide-details":"","type":"text","required":"","readonly":"","append-icon":"mdi-plus"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"upload",attrs:{"text":""},on:{"click":function($event){return _vm.onLogoFile()}}},[_vm._v("Upload ")])]},proxy:true}],null,true),model:{value:(_vm.logoImage),callback:function ($$v) {_vm.logoImage=$$v},expression:"logoImage"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Phone Number","solo":"","hide-details":"","type":"tel","required":""},model:{value:(_vm.onboardData.phoneNumber),callback:function ($$v) {_vm.$set(_vm.onboardData, "phoneNumber", $$v)},expression:"onboardData.phoneNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Country","solo":"","hide-details":"","type":"text","items":_vm.countries,"append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","required":""},on:{"change":_vm.getCountiesOrState},model:{value:(_vm.onboardData.address.country),callback:function ($$v) {_vm.$set(_vm.onboardData.address, "country", $$v)},expression:"onboardData.address.country"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"County Or State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"County Or State","solo":"","hide-details":"","type":"text","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","items":_vm.countiesOrStates,"required":""},on:{"change":_vm.getCity},model:{value:(_vm.onboardData.address.county),callback:function ($$v) {_vm.$set(_vm.onboardData.address, "county", $$v)},expression:"onboardData.address.county"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Town/City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Town/City","append-icon":"mdi-chevron-down","solo":"","hide-details":"","type":"text","required":"","items":_vm.cities},model:{value:(_vm.onboardData.address.city),callback:function ($$v) {_vm.$set(_vm.onboardData.address, "city", $$v)},expression:"onboardData.address.city"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"3"}},[_c('validationProvider',{attrs:{"name":"Postal Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Postal Code","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.onboardData.address.postalCode),callback:function ($$v) {_vm.$set(_vm.onboardData.address, "postalCode", $$v)},expression:"onboardData.address.postalCode"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"9"}},[_c('validationProvider',{attrs:{"name":"House Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"House Address","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.onboardData.address.houseNumber),callback:function ($$v) {_vm.$set(_vm.onboardData.address, "houseNumber", $$v)},expression:"onboardData.address.houseNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('base-button',{staticClass:"mt-8",attrs:{"width":"100%","button-text":"Complete","loading":_vm.loading,"type":"submit"}})],1)]}}])})],1)]),_c('modal',{attrs:{"dialog":_vm.showModal,"icon":_vm.check,"section":"main","title":"Great, You are all Set!","description":"You are ready to start applying to the hoop camp.","additional-text":"Access Dashboard"},on:{"close":_vm.routeToDashboard}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }